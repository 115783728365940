import Aboutus from "./pages/Aboutus";
import ContactUs from "./pages/ContactUs";
import Error from "./pages/Error";
import Home from "./pages/Home";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WorkInProgress from "./pages/WorkInProgress";


function App() {
  return (
   <>
   <Router>
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/contact-us" element={<ContactUs/>}></Route>
      <Route path="/about-us" element={<Aboutus/>}></Route>
      <Route path="/:id" element={<Error/>}></Route>
      {/* Process */}
      <Route path="/from-the-founder" element={<WorkInProgress/>}></Route>
      <Route path="/innovation" element={<WorkInProgress/>}></Route>
    </Routes>
   </Router>
   </>
  );
}

export default App;
