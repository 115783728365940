import React from 'react'
import Nav from '../Componets/Nav'
import Pixl_Visual from '../Componets/Aboutus/Pixl_Visual'
import Our_story from '../Componets/Aboutus/Our_story'
import { Helmet } from 'react-helmet';

const Aboutus = () => {
  return (
   <>
   <Nav/>
   <Pixl_Visual/>
   <Our_story/>
   </>
  )
}

export default Aboutus