import React, { useState, useEffect } from 'react';
import Nav from "../Componets/Nav";
import "../css/contact.css";
import layer_Img from '../assets/contact/layer.png'
import Object_Img from '../assets/contact/object.png'
import { Helmet } from 'react-helmet';
import Contact_heading from '../Componets/contact_Page/Contact_heading';
import Lets_Talk_efforts from '../Componets/contact_Page/Lets_Talk_efforts';
import Inquire_Now from '../Componets/contact_Page/Inquire_Now';
import Address from '../Componets/contact_Page/Address';

const ContactUs = () => {
   // Store the meta description in state
   const [metaDescription, setMetaDescription] = useState("");

   // You can dynamically update the meta description if needed
   useEffect(() => {
     // You could call an API or some logic to update the description here
     setMetaDescription("Connect with PIXL VISUALEFFECTS in Pondicherry for advanced VFX solutions, FX simulations, and visual effects consultation.");
   }, []); // This effect runs once when the component is mounted
  return (
    
    <div className="contact-container">
     {/* ---------------- SEO ------------------ */}
     <Helmet>
        <title>Contact Us</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      {/* ---------------SEO END---------------- */}
      <Nav />
      {/* fixed robot design */}
      <div className="robot-container">
        <div className="robot">
          <div className="layer">
            <img src={layer_Img} alt="" />
          </div>
          <div className="layer">
            <img src={Object_Img} alt="" />
          </div>
        </div>
      </div>
      {/* ----------------------------------------- */}
    <Contact_heading/>
    <Lets_Talk_efforts/>
    <Inquire_Now/>
    <Address/>
    </div>
  );
};

export default ContactUs;
